<template>
    <div class="my-5">

        <el-row type="flex" justify="space-between">
            <el-col :span="6">
                <el-input v-model="search" placeholder="Buscar Pedidos" clearable suffix-icon="el-icon-search"/>
            </el-col>
        </el-row>

        <el-row class="mt-4 px-5 py-2 bg-white border border-gray rounded">
            <el-col>
                <el-table
                    class="table m-0"
                    ref="orderTable"
                    :span-method="arraySpanMethod"
                    :row-class-name="'cursor-pointer'"
                    :data="tableRows"
                    @row-click="goToOrderDetail">

                    <el-table-column
                        label="Nº"
                        width="70"
                        prop="number"
                        sortable>

                        <template slot-scope="data">
                            <span class="number-cell">{{ data.row.number }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="Tipo"
                        prop="byBudget">

                        <template slot-scope="data">
                            <cake-by-budget :by-budget="data.row.byBudget"/>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="Cliente"
                        prop="client.name"
                        sortable>
                    </el-table-column>

                    <el-table-column
                        label="Fecha"
                        prop="createdAt"
                        sortable>

                        <template slot-scope="data">
                            <date-formatted :date="new Date(data.row.createdAt)"/>
                        </template>
                    </el-table-column>

                  <el-table-column
                      label="Entrega"
                      prop="deliveryDate"
                      sortable>

                    <template slot-scope="data">
                      <date-formatted :date="new Date(data.row.deliveryDate)"/>
                    </template>
                  </el-table-column>

                    <el-table-column
                        label="Ciudad"
                        prop="cake.cakeshop.city"
                        :filters="citiesFilter"
                        :filter-method="filterCities">
                    </el-table-column>

                    <el-table-column
                        min-width="140"
                        label="Pastelería"
                        prop="cake.cakeshop.name">
                    </el-table-column>

                    <el-table-column
                        label="Estado"
                        prop="status"
                        width="140"
                        :filters="statusFilters"
                        :filter-method="filterHandler">

                        <template slot-scope="data">
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <order-status-badge :status="data.row.status"/>
                                <img
                                    v-if="data.row.lastPayment && data.row.lastPayment.status !== 'CHARGED'"
                                    src="@/assets/images/payment_failed.png"
                                    class="ml-2 icon-failed"
                                    alt="Icono para pago fallido">
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column align="right" width="30">
                        <template slot="header">
                            <svg-icon
                                style="width: 1.5em; height: 1.5em;"
                                icon-class="reset"
                                class="cursor-pointer"
                                @click="resetFilters"/>
                        </template>
                    </el-table-column>

                </el-table>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import CakeByBudget from '../../components/CakeByBudget';
    import OrderStatusBadge from '../../components/OrderStatusBadge';
    import DateFormatted from '../../components/DateFormatted';

    export default {
        name: "OrderList",
        components: {
            CakeByBudget,
            OrderStatusBadge,
            DateFormatted
        },
        data() {
            return {
                search: "",
                statusFilters: [
                    {text: 'Solicitado', value: 'REQUESTED'},
                    {text: 'Pendiente', value: 'PENDING_APPROVAL'},
                    {text: 'Confirmado', value: 'CONFIRMED'},
                    {text: 'Cancelado', value: 'CANCELLED'},
                    {text: 'Para entregar', value: 'READY_TO_DELIVER'},
                    {text: 'Entregado', value: 'DELIVERED'}
                ]
            }
        },
        created() {
            this.$store.dispatch('loadOrders')
        },
        computed: {
            ...mapGetters([
                'orders',
                'role'
            ]),
            citiesFilter() {
                let cities = this.orders.map(item => item.cake.cakeshop.city);
                cities = cities.filter((value, index, self) => self.indexOf(value) === index);
                return cities.map(item => {
                    return {text: item, value: item}
                })
            },
            tableRows() {
                return this.orders.filter(data => !this.search
                    || data.number.toString().toLowerCase().includes(this.search.toLowerCase())
                    || this.formatDate(data.createdAt).includes(this.search.toLowerCase())
                    || this.formatDate(data.deliveryDate).includes(this.search.toLowerCase())
                    || data.cake.cakeshop.name.toLowerCase().includes(this.search.toLowerCase())
                    || data.client.name.toLowerCase().includes(this.search.toLowerCase()))
            }
        },
        methods: {
            formatDate(dateString) {
              let appendLeadingZeroes = function(n) {
                if (n <= 9) {
                  return "0" + n;
                }
                return n
              }
              let date = new Date(dateString);
              return appendLeadingZeroes(date.getDate())
                  + "/" + appendLeadingZeroes(date.getMonth() + 1)
                  + "/" + date.getFullYear();
            },
            filterHandler(value, row) {
                return row.status === value;
            },
            filterCities(value, row) {
                return row.cake.cakeshop.city === value;
            },
            resetFilters() {
                this.$refs.orderTable.clearFilter();
            },
            goToOrderDetail(row) {
                this.$router.push({name: 'order-profile', params: {uuid: row.uuid}})
            },
            arraySpanMethod({ columnIndex }) {
              if (columnIndex === 7) {
                return [1, 2];
              } else {
                return [1, 1];
              }
            },
        }
    }
</script>

<style scoped>
    .number-cell {
        font-weight: 600;
        letter-spacing: 0.02em;
    }

    .icon-failed {
        width: 25px;
    }
</style>
